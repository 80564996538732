const SHADOW_OPACITY = "0.6";
const INITIAL_DELAY = 300;

let globalCleanup = null;

const waitForAnimation = async (element, className) => {
  return new Promise((resolve) => {
    element.classList.add(className);
    element.addEventListener(
      "animationend",
      () => {
        element.classList.remove(className);
        resolve();
      },
      { once: true }
    );
  });
};

/* "visible" meaning "scrolled into viewport" */
const waitUntilVisible = (e, callback) => {
  /* Guard against divide-by-zero */
  const scrollHeight = e.scrollHeight > 0 ? e.scrollHeight : 1;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.some((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(e);
          callback();
          return true;
        }
      });
    },
    {
      root: document,
      /* 100px from viewport to make sure the arrow is visible, if < 100px, just half */
      threshold: scrollHeight > 100.0 ? 100.0 / scrollHeight : 0.5,
    }
  );

  observer.observe(e);
};

const markAttached = (container) => {
  container.dataset.scrollHintAttached = "true";
};
const isAlreadyAttached = (container) => container.dataset.scrollHintAttached === "true";
const clearAttached = (container) => {
  delete container.dataset.scrollHintAttached;
};

const decideApproach = (container) => {
  const childTableContainer = container.querySelector(":scope > .table-container");
  if (childTableContainer) {
    return {
      isWrapperMode: false,
      scrollContainer: childTableContainer,
      shadowContainer: childTableContainer.parentElement,
    };
  } else {
    return {
      isWrapperMode: true,
      scrollContainer: container,
      shadowContainer: null,
    };
  }
};

const createShadows = ({ isWrapperMode, container, shadowContainer }) => {
  let finalShadowContainer = shadowContainer;

  if (isWrapperMode) {
    const wrapper = document.createElement("div");
    wrapper.classList.add("scroll-hint-wrapper");
    container.parentNode.insertBefore(wrapper, container);
    wrapper.appendChild(container);
    finalShadowContainer = wrapper;
  }

  if (!finalShadowContainer) return null;

  if (window.getComputedStyle(finalShadowContainer).position === "static") {
    finalShadowContainer.style.position = "relative";
  }

  const leftShadow = document.createElement("div");
  leftShadow.className = "scroll-hint-shadow left";
  leftShadow.style.opacity = "0";

  const rightShadow = document.createElement("div");
  rightShadow.className = "scroll-hint-shadow right";
  rightShadow.style.opacity = "0";

  finalShadowContainer.appendChild(leftShadow);
  finalShadowContainer.appendChild(rightShadow);

  return { shadowContainer: finalShadowContainer, leftShadow, rightShadow };
};

const commonShadowSetup = ({ scrollContainer, leftShadow, rightShadow, calcScrollDistance }) => {
  const updateVisibility = () => {
    if (!document.contains(scrollContainer)) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
    const maxScroll = scrollWidth - clientWidth;
    leftShadow.style.opacity = scrollLeft > 5 ? SHADOW_OPACITY : "0";
    rightShadow.style.opacity = maxScroll > 5 && scrollLeft < maxScroll - 5 ? SHADOW_OPACITY : "0";
  };

  const updateHeights = () => {
    if (!document.contains(scrollContainer)) return;
    const h = scrollContainer.offsetHeight;
    leftShadow.style.height = h + "px";
    rightShadow.style.height = h + "px";
  };

  const updateHeightsAndVisibility = () => {
    updateHeights();
    updateVisibility();
  };

  const handleLeftClick = () =>
    scrollContainer.scrollBy({ left: -calcScrollDistance(), behavior: "smooth" });
  const handleRightClick = () =>
    scrollContainer.scrollBy({ left: calcScrollDistance(), behavior: "smooth" });

  scrollContainer.addEventListener("scroll", updateVisibility);
  window.addEventListener("resize", updateHeightsAndVisibility);
  leftShadow.addEventListener("click", handleLeftClick);
  rightShadow.addEventListener("click", handleRightClick);

  updateHeightsAndVisibility();

  waitUntilVisible(rightShadow, async () => {
    if (document.contains(rightShadow)) {
      await waitForAnimation(rightShadow, "pulse-shadow");
    }
  });

  return () => {
    window.removeEventListener("resize", updateHeightsAndVisibility);
    scrollContainer.removeEventListener("scroll", updateVisibility);
    leftShadow.removeEventListener("click", handleLeftClick);
    rightShadow.removeEventListener("click", handleRightClick);
    leftShadow.remove();
    rightShadow.remove();
  };
};

const setupSingleContainer = (container) => {
  if (!container.hasAttribute("data-scroll-hint")) return null;
  if (isAlreadyAttached(container)) return null;

  const { isWrapperMode, scrollContainer, shadowContainer } = decideApproach(container);
  markAttached(container);

  const result = createShadows({ isWrapperMode, container, shadowContainer });
  if (!result) return null;

  const { leftShadow, rightShadow } = result;
  let finalShadowContainer = result.shadowContainer;

  // detect if there's a sticky first column (like on Nutrition Breakdown)
  let stickyColWidth = 0;
  let hasStickyFirstColumn = false;

  const table = scrollContainer.querySelector("table");
  if (table) {
    const firstCol = table.querySelector("th:first-child, td:first-child");
    if (firstCol && window.getComputedStyle(firstCol).position === "sticky") {
      hasStickyFirstColumn = true;
      stickyColWidth = firstCol.offsetWidth;
      leftShadow.style.left = `${stickyColWidth}px`;
    }
  }

  let calcScrollDistance;
  if (hasStickyFirstColumn) {
    calcScrollDistance = () => {
      const visibleWidth = scrollContainer.clientWidth - stickyColWidth;
      return Math.floor(visibleWidth * 0.75);
    };
  } else {
    calcScrollDistance = () => {
      return Math.floor(scrollContainer.clientWidth * 0.75);
    };
  }

  const cleanupCommon = commonShadowSetup({
    scrollContainer,
    leftShadow,
    rightShadow,
    calcScrollDistance,
  });

  const handleResizeOffset = () => {
    if (hasStickyFirstColumn && table) {
      const firstCol = table.querySelector("th:first-child, td:first-child");
      if (firstCol) {
        stickyColWidth = firstCol.offsetWidth;
        leftShadow.style.left = `${stickyColWidth}px`;
      }
    }
  };
  window.addEventListener("resize", handleResizeOffset);

  return () => {
    window.removeEventListener("resize", handleResizeOffset);
    cleanupCommon();

    if (isWrapperMode && finalShadowContainer !== scrollContainer) {
      leftShadow.remove();
      rightShadow.remove();
      const originalParent = finalShadowContainer.parentNode;
      if (originalParent) {
        originalParent.insertBefore(scrollContainer, finalShadowContainer);
        finalShadowContainer.remove();
      }
    }
    clearAttached(container);
  };
};

const processScrollHints = () => {
  const containers = document.querySelectorAll("[data-scroll-hint]");
  const cleanups = [];
  containers.forEach((container) => {
    const c = setupSingleContainer(container);
    if (c) cleanups.push(c);
  });
  return () => cleanups.forEach((fn) => fn());
};

const cleanup = () => {
  if (globalCleanup) {
    globalCleanup();
    globalCleanup = null;
  }
};

const scan = () => {
  cleanup();
  globalCleanup = processScrollHints();
};

document.addEventListener("DOMContentLoaded", () => {
  setTimeout(scan, INITIAL_DELAY);
});
document.addEventListener("partial:replace", () => {
  setTimeout(scan, INITIAL_DELAY);
});
window.addEventListener("beforeunload", cleanup);
