import tippy from "tippy.js";
import { isNil } from "lodash-es";

// Set up a tippy hint - for use outside a Vue context.
// If you're in Vue, prefer using icon-hint.vue or wrap-with-hint.vue,
// which are reactive if the hint changes.
export const setupTippyHint = (elem) => {
  const allowHTML = elem.dataset.hintHtml !== undefined;
  const content = allowHTML ? elem.dataset.hintHtml : elem.dataset.hint;

  tippy(elem, {
    content,
    allowHTML,

    // otherwise 'interactive' hints can end up small since they will be appended to parent
    appendTo: document.body,

    // allows clicking links
    interactive: !isNil(elem.dataset.hintInteractive) && elem.dataset.hintInteractive !== "false",
    trigger: "mouseenter focus click",
    theme: "recipal",
  });

  /* When used in sortable tables, clicking shouldn't sort. */
  /* If you want click to be allowed, use data-hint-allow-click-link. */
  if (!elem.dataset.hintAllowClickLink) {
    elem.addEventListener("click", (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
    });
  }
};
