document.addEventListener("DOMContentLoaded", (_ev) => {
  const olarkFacade = document.querySelector("#olark-facade");
  // We didn't render the olark partial
  if (!olarkFacade && !window.skipOlarkFacade) {
    return;
  }

  const olarkLoader = (o, l, a, r, k, y) => {
    if (o.olark) return;
    r = "script";
    y = l.createElement(r);
    r = l.getElementsByTagName(r)[0];
    y.async = 1;
    y.src = "//" + a;
    r.parentNode.insertBefore(y, r);
    y = o.olark = function () {
      k.s.push(arguments);
      k.t.push(+new Date());
    };
    y.extend = function (i, j) {
      y("extend", i, j);
    };
    y.identify = function (i) {
      y("identify", (k.i = i));
    };
    y.configure = function (i, j) {
      y("configure", i, j);
      k.c[i] = j;
    };
    k = y._ = { s: [], t: [+new Date()], c: {}, l: a };
  };

  const loadOlark = () => {
    olarkLoader(window, document, "static.olark.com/jsclient/loader.js");

    const olark = window.olark;
    olark.identify("1774-394-10-9547");

    olark.configure("system.hb_custom_style", {
      general: {
        fonts: ["Lato", "Helvetica", "Arial", "sans-serif"],
        corners: "",
        secondaryColor: "",
      },
    });

    if (window.currentUser && window.currentUser.email) {
      olark("api.visitor.updateEmailAddress", { emailAddress: window.currentUser.email });
    }

    olark("api.box.onExpand", () => (document.cookie = "isChatting=true; max-age=86400; path=/"));
    olark("api.box.onShrink", () => (document.cookie = "isChatting=; max-age=0; path=/"));
  };

  if (!olarkFacade) {
    loadOlark();
    return;
  }

  olarkFacade.addEventListener(
    "click",
    (_ev) => {
      loadOlark();

      const olark = window.olark;
      olark("api.box.onExpand", () => olarkFacade.remove());
      olark("api.chat.onReady", () => olark("api.box.expand"));
    },
    { once: true }
  );
});
